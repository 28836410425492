import React from 'react';

import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1 className="mt-4">Not Found</h1>
          <p>The page you requested could not be found</p>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
